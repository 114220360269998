<template>
  <div id="app1">
    <div class="header MT_3 PL_2 PB_4" @click="goBack">
      <img src="../assets/icon30.png" alt="">
      <span>2022年四季度企业三表</span>
    </div>
    <div class="modeList">
      <div class="modeItem MB_3" v-for="(item,index) in 3" :key="index">
        <div class="modeitemtitle">公司财务报表 </div>
        <div class="modeitemcompany MTB_1">研耀(上海)信息科技有限公司</div>
        <div class="modeflex MTB_2">
          <div style="color:#888888"><van-icon  name="clock-o" /> 2022-12-22</div>
          <div class="modebtn">查看</div>
        </div>
      </div>
    </div>
  </div> 
</template>
<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    goBack(){
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped>
#app1{
  background-color: #F7F7F7;
  height:100vh;
}
</style>